import { createAction, createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { initialObjectState } from "../initialState";
import { solutionsPageModel } from "../../shared/models/mainComponents/solutionsPageModel";
import Service from "../../service";

const hydrate = createAction(HYDRATE);

const solutionsPageSlice = createSlice({
  name: "solutions",
  initialState: initialObjectState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.item = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    setCategoryID: (state, { payload }) => {
      state.solutionCategoryID = payload;
      state.loading = false;
      state.hasErrors = false;
      state.hasMessage = null;
    },
    failed: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.hasMessage = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload.solutions,
      };
    });
  },
});

export const { setItemList, failed, setCategoryID } =
  solutionsPageSlice.actions;

export const solutionsPageData = (state) => state.solutions.item;
export const selectCategoryID = (state) => state.solutions.solutionCategoryID;

export default solutionsPageSlice.reducer;

export function getSolutionData(ctx) {
  return async (dispatch, getState) => {
    const service = new Service(ctx);
    const requestData = {
      data: {
        SolutionCategoryID: ctx?.query?.SolutionCategoryID,
      },
    };
    try {
      const res = await service.catalog.GetSolution(requestData);
      const newData = solutionsPageModel(res, ctx.userSettings.languageID);

      if (res?.data && !res.hasFailed) {
        dispatch(setItemList(newData));
      } else {
        dispatch(failed(res?.messages?.[0]?.description));
      }
    } catch (error) {
      console.error("getProductsData error:", error);
    }
  };
}
